import React, {useState} from "react"
import c from "./seedDataView.module.scss"
import {usePubNub} from "pubnub-react";
import LoadingComponent from "../components/shared/loadingComponent";

const seedConversations = [
  {
    id: "chats.aa3aec5729f641b9ac7fe91df9550737",
    name: "First Conversation"
  },
  {
    id: "chats.f636b950649e41da94244496e26386a5",
    name: "Second Conversation"
  },
  {
    id: "chats.cec160e1f00f42fa9782b39616883d56",
    name: "Third Conversations"
  }
];

export default function SeedDataView() {
  const pubnub = usePubNub();
  const [isLoading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const seedData = () => {
    setLoading(true);
    setShowSuccess(false);

    const promises = seedConversations.map(async c => {
      try {
        const response = await pubnub.objects.setChannelMetadata({
          channel: c.id,
          data: {
            name: c.name,
            custom: {
              foobar: "55"
            }
          },
        });

        if (response.status !== 200) {
          console.error(`Unknown error initializing data for channel ${ c.id }.`);
        }

      } catch (e) {
        console.error(e);
      }
    });

    Promise.all(promises)
      .then(() => {
        setLoading(false);
        setShowSuccess(true);
      });
  };

  const deleteMessages = () => {
    setLoading(true);
    setShowSuccess(false);

    const promises = seedConversations.map(async c => {
      try {
        await pubnub.deleteMessages({
          channel: c.id
        });
      } catch (e) {
        // there seems to be a bug in the library, ignore errors
      }
    });

    Promise.all(promises)
      .then(() => {
        setLoading(false);
        setShowSuccess(true);
      })
  };

  const deleteChannels = () => {
    setLoading(true);
    setShowSuccess(false);

    const promises = seedConversations.map(async c => {
      try {
        const response = await pubnub.objects.removeChannelMetadata({
          channel: c.id,
        });

        if (response.status !== 200) {
          console.error(`Unknown error initializing data for channel ${ c.id }.`);
        }

      } catch (e) {
        console.error(e);
      }
    });

    Promise.all(promises)
      .then(() => {
        setLoading(false);
        setShowSuccess(true);
      });
  }

  return (
    <div className={ c.container }>
      <p>This is to seed and control test data in Pubnub.</p>

      {isLoading && (
        <LoadingComponent className={ c.loading } />
      )}

      {!isLoading && (
        <>
          <div className={ c.btnContainer }>
            <button onClick={ seedData }>Create Channels</button>
            <button onClick={ deleteMessages }>Delete Messages</button>
            <button onClick={ deleteChannels }>Delete Channels</button>
          </div>
          {showSuccess && (
            <div className={ c.success }>Success!</div>
          )}
        </>
      )}

    </div>
  );
}
