export const channelPrefix = "chats.";
export const getChannelNameById = (conversationId) => {
  return channelPrefix + conversationId;
}

export const draftChannelPrefix = "chat-drafts.";
export const getDraftsChannelNameById = (conversationId) => {
  return draftChannelPrefix + conversationId;
}

export const adminTypingChannelPrefix = "chat-admin-typing.";
export const getAdminTypingChannelNameById = (conversationId) => {
  return adminTypingChannelPrefix + conversationId;
}

export const adminTypingNoteChannelPrefix = "chat-admin-typing-note.";
export const getAdminTypingNoteChannelNameById = (conversationId) => {
  return adminTypingNoteChannelPrefix + conversationId;
}

export const notesChannelPrefix = "chat-notes.";
export const getNotesChannelNameById = (conversationId) => {
  return notesChannelPrefix + conversationId;
}
