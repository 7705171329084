import React, {useEffect, useState} from "react"
import c from "./mainView.module.scss"
import Conversation from "../features/conversation/conversation";
import Sidebar from "../components/sidebar/sidebar";
import classNames from "classnames";
import {useParams} from "@reach/router";

export default function MainView() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { conversationId } = useParams()

  // this will open the menu view on mobile when no conversation is selected
  // (which can only happen on first page load)
  useEffect(() => {
    setMobileMenuOpen(!conversationId);
  }, [conversationId]);

  return (
    <div className={ classNames(c.container, { [c.isMobileMenuOpen]: isMobileMenuOpen })  }>
      <div className={ c.leftPane }>
        <Sidebar onMobileMenuClose={ () => setMobileMenuOpen(false) } />
      </div>
      <div className={ c.rightPane }>
        <Conversation onOpenMobileMenu={ () => setMobileMenuOpen(true) } />
      </div>
    </div>
  );
}
