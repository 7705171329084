import React, {useEffect, useState} from "react"
import c from "./conversation.module.scss"
import ConversationStream from "./conversationStream";
import MessageCompose from "./messageCompose";
import ConversationHeader from "./conversationHeader";
import {useParams} from "@reach/router";
import ConversationMain from "./conversationMain";
import ConversationDetails from "./conversationDetails";

export default function Conversation({ onOpenMobileMenu }) {
  const { conversationId } = useParams()

  if (!conversationId) {
    return (
      <div className={ c.placeholder }>Please select a conversation.</div>
    );
  }

  return (
    <div className={ c.container }>
      <div className={ c.mainPanel }>
        <ConversationMain onOpenMobileMenu={ onOpenMobileMenu } />
      </div>
      <div className={ c.detailsPanel }>
        <ConversationDetails />
      </div>
    </div>
  );
}
