import React, {useEffect, useState} from "react"
import c from "./conversationPartVisitorDraft.module.scss"
import Avatar from "../../../components/shared/avatar";
import {PulseLoader} from "react-spinners";

export default function ConversationPartVisitorDraft({ draft }) {
  const { text } = draft.message;

  // We just need some state that we can change during the interval so that the
  // component rerenders. We don't actually use the counter for anything.
  const [unusedCounter, setCounter] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(c => c + 1);
    }, 1000); // 1 second

    return () => clearInterval(interval);
  }, []);

  const TYPING_INDICATOR_DURATION_SECONDS = 5;
  const draftTimestamp = parseInt(draft.timetoken) / 10000
  const isTyping = (Date.now() - draftTimestamp) < (TYPING_INDICATOR_DURATION_SECONDS * 1000);

  return (
    <div className={ c.container }>
      <Avatar className={ c.avatar } />

      <div className={ c.content }>
        <div className={ c.bubbleCtn }>
          <div className={ c.bubble }>
            {isTyping && (
              <div className={ c.typingIndicator }>
                <PulseLoader color="#222" size="8" />
              </div>
            )}
            <div className={ c.draftText }>{ text }</div>
          </div>
        </div>
      </div>
    </div>
  );
}
