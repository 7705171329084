import React from "react"
import c from "./conversationPartMessage.module.scss"
import classNames from "classnames";
import {useSelector} from "react-redux";
import DateDisplay from "../../../components/shared/dateDisplay";
import Avatar from "../../../components/shared/avatar";
import {notesChannelPrefix} from "../../../utils/channelNames";

export default function ConversationPartMessageOrNote({ part }) {
  const { timetoken } = part.raw;
  const { text } = part.raw.message;
  const { authorId, authorType,} = part.raw.userMetadata;

  const isNote = part.raw.channel.includes(notesChannelPrefix);

  const author = useSelector(state => state.users.byId[authorId]);

  const isForAdmin = authorType === "admin";
  const cx = classNames(c.container, {
    [c.forAdmin]: isForAdmin,
    [c.forVisitor]: !isForAdmin,
    [c.isFirstInGroup]: part.isFirstInGroup,
    [c.isNote]: isNote
  });

  return (
    <div className={ cx }>
      {part.isFirstInGroup && (
        <Avatar
          className={ c.avatar }
          isForAdmin={ isForAdmin }
          profileUrl={ author ? author.profileUrl : undefined } />
      )}

      <div className={ c.content }>
        <div className={ c.bubbleCtn }>
          <div className={ c.bubble }>
            { text }
          </div>
        </div>

        {part.isLastInGroup && (
          <div className={ c.dateTime }>
            <DateDisplay timetoken={ timetoken } />
          </div>
        )}
      </div>
    </div>
  )
}
