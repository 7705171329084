import React from "react"
import c from "./sidebar.module.scss"
import LoginUserInfo from "./loginUserInfo";
import ConversationList from "../../features/conversationList/conversationList";
import logoImg from "../../images/logo.png";

export default function Sidebar({ onMobileMenuClose }) {
  return (
    <div className={ c.container }>
      <div className={ c.logo }>
        <img src={ logoImg } />
      </div>

      <LoginUserInfo className={ c.loginStatus } />

      <ConversationList onMobileMenuClose={ onMobileMenuClose } />
    </div>
  );
}
