import React from "react"
import c from "./avatar.module.scss"
import classNames from "classnames";

export default function Avatar({ isForAdmin, profileUrl, size = 32, className }) {
  const cx = classNames(c.container, className, {
    [c.forAdmin]: isForAdmin,
    [c.forVisitor]: !isForAdmin,
  });

  const styles = {
    width: size,
    height: size,
    borderRadius: size/2
  }

  return (
    <div className={ cx } style={ styles }>
      {isForAdmin && (
        <img src={ profileUrl } />
      )}
      {!isForAdmin && (
        <span>SV</span>
      )}
    </div>
  );
}
