import {loadConversations} from "../conversationList/conversationsSlice";
import {initCompleted} from "./initSlice";
import {loadAndInitUsers} from "../users/usersActions";
import {
  adminTypingChannelPrefix, adminTypingNoteChannelPrefix,
  channelPrefix,
  draftChannelPrefix,
  notesChannelPrefix
} from "../../utils/channelNames";

export const doInit = (authUser) => async (dispatch, getState, context) => {
  const pubnub = context.pubnub.api;

  await Promise.all([
    dispatch(loadConversations()),
    dispatch(loadAndInitUsers(authUser))
  ]);

  // subscribe to all the channel we want to listen to
  pubnub.subscribe({
    channels: [
      `${ channelPrefix }*`, // all the normal messages
      `${ draftChannelPrefix }*`,  // all the drafts written by visitors
      `${ adminTypingChannelPrefix }*`, // admin typing signals
      `${ adminTypingNoteChannelPrefix }*`, // admin typing signals for notes
      `${ notesChannelPrefix }*` // all the internal notes written by admins
    ],
    withPresence: true,
  });

  // by now, the currently logged in user MUST be present in the store
  const loggedInUser = getState().users.byId[authUser.id];
  if (!loggedInUser) {
    throw Error("loggedInUser cannot be unspecified at this point")
  }

  dispatch(initCompleted(loggedInUser));
}
