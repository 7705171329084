import React, {useEffect} from "react"
import c from "./loginUserInfo.module.scss"
import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames"
import {useSelector} from "react-redux";

export default function LoginUserInfo({ className }) {
  const { logout } = useAuth0();
  const user = useSelector(state => state.init.loggedInUser);

  return (
    <div className={ classNames(c.container, className) }>
      <div className={ c.avatar }><img src={ user.profileUrl } /></div>
      <div className={ c.nameCtn }>
        <div className={ c.name }>{ user.name }</div>
        <div onClick={() => logout() } className={ c.logout }>Log Out</div>
      </div>
    </div>
  );
}
