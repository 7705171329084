import React from "react"
import c from "./conversationListItem.module.scss"
import {Link} from "@reach/router";
import classNames from "classnames";
import Avatar from "../../components/shared/avatar";
import DateDisplay from "../../components/shared/dateDisplay";

export default function ConversationListItem(
  {
    id,
    data,
    isActive,
    className,
    onMobileMenuClose
  }) {

  const location = "Unknown Location";

  return (
    <Link
      to={ `/${ id }/` }
      onClick={ onMobileMenuClose }
      className={ classNames(c.container, className, { [c.active]: isActive }) }
    >
      <div className={ c.top }>
        <Avatar className={ c.visitorAvatar } />
        <div className={ c.visitorInfo }>
          <div className={ c.name }>
            { data.name }
          </div>
          <div className={ c.location }>
            { location }
          </div>
        </div>
        <div className={ c.date }>
          <DateDisplay timetoken={ data.lastMessage.timetoken } shortFormat={ true } />
        </div>
      </div>

      {data.lastMessage.type === "text" && (
        <div className={ c.bottom }>
          {/*{data.lastMessage.authorType === "admin" && (*/}
          {/*  */}
          {/*)}*/}
          <div className={ c.message }>
            { data.lastMessage.text }
          </div>
        </div>
      )}
    </Link>
  );
}
