import React, {useEffect, useState} from "react"
import c from "./conversationList.module.scss"

import classNames from "classnames";
import {useSelector} from "react-redux";
import {channelPrefix, getChannelNameById, getDraftsChannelNameById} from "../../utils/channelNames";
import {Link, useParams} from "@reach/router";
import ConversationListItem from "./conversationListItem";

const selectConversationsWithLastMessage = state => {
  const all = Object.values(state.conversations.byId).map(conversation => {
    const conversationId = conversation.id.split(channelPrefix)[1];

    const messages = state.messages.byId[getChannelNameById(conversationId)];
    const drafts = state.messages.byId[getDraftsChannelNameById(conversationId)];

    let lastMessage;
    if (messages && messages.length > 0) {
      lastMessage = messages[messages.length - 1];
    }

    let lastDraft;
    if (drafts && drafts.length > 0) {
      lastDraft = drafts[drafts.length - 1];
    }

    /*
      Use either the last chat message or the last draft, depending on which one is
      newer for the "last message data" (unless the last draft is empty)
     */
    let lastMessageOrDraft;
    if (lastMessage && (
          !lastDraft ||
          lastDraft.message.text.trim().length === 0 ||
          lastMessage.timetoken > lastDraft.timetoken
        )
    ) {
      lastMessageOrDraft = lastMessage;
    } else if (lastDraft) {
      lastMessageOrDraft = lastDraft;
    }

    let lastMessageData;
    if (lastMessageOrDraft) {
      const { timetoken } = lastMessageOrDraft;
      const { text } = lastMessageOrDraft.message;
      const { type, authorId, authorType } = lastMessageOrDraft.userMetadata;

      lastMessageData = {
        timetoken,
        text,
        type,
        authorId,
        authorType
      }
    }

    return {
      ...conversation,
      lastMessage: lastMessageData
    }
  });

  // filter out conversation that don't have a message, should never happen
  return all
    .filter(c => c.lastMessage);
}

export default function ConversationList({ onMobileMenuClose }) {
  const { conversationId } = useParams()
  const conversations = useSelector(selectConversationsWithLastMessage);

  const itemEls = conversations
    // sort by last message time, ascending
    .sort((a,b) => b.lastMessage.timetoken - a.lastMessage.timetoken)
    .map((conversation, idx) => {

    const id = conversation.id.split(channelPrefix)[1];
    const isActive = conversationId === id;

    return (
      <ConversationListItem
        key={ idx }
        id={ id }
        data={ conversation }
        isActive={ isActive }
        className={ c.item }
        onMobileMenuClose={ onMobileMenuClose }
      />
    );
  });

  return (
    <div className={ c.container }>
      <div className={ c.title }>Conversations</div>

      <div className={ c.items}>
        { itemEls }
      </div>
    </div>
  );
}
