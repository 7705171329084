import React, {useEffect, useRef, useState} from "react"
import c from "./conversationStream.module.scss"
import classNames from "classnames"
import {
  getChannelNameById,
  getDraftsChannelNameById,
  getAdminTypingChannelNameById,
  getNotesChannelNameById,
  getAdminTypingNoteChannelNameById, notesChannelPrefix, adminTypingNoteChannelPrefix
} from "../../utils/channelNames";
import {useSelector} from "react-redux";
import {useParams} from "@reach/router";
import ConversationPartMessageOrNote from "./parts/conversationPartMessageOrNote";
import {transformToConversationParts} from "./transformToConversationParts";
import ConversationPartVisitorDraft from "./parts/conversationPartVisitorDraft";
import ConversationPartAdminTypingIndicators from "./parts/conversationPartAdminTypingIndicators";

const scrollToBottom = (el) => {
  el.scrollTop = el.scrollHeight - el.clientHeight;
};

const groupSignalsByPublisherId = (adminTypingSignals) => {
  return adminTypingSignals.reduce((allGroups, signal) => {
    const publisherId = signal['publisher'];

    // use existing group or initialize
    const group = allGroups[publisherId] || [];

    // add this signal to the group
    group.push(signal);

    allGroups[publisherId] = group;
    return allGroups;
  }, {});
};

export default function ConversationStream({ className }) {
  // We just need some state that we can change during the interval so that the
  // component rerenders. We don't actually use the counter for anything.
  const [unusedCounter, setCounter ] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(c=>c+1);
    }, 1000)
    return () => clearInterval(interval);
  },[]);

  const { conversationId } = useParams()

  const messages = useSelector(
    state => state.messages.byId[getChannelNameById(conversationId)]) || [];
  const lastDraft = useSelector(
    state => {
      const drafts = state.messages.byId[getDraftsChannelNameById(conversationId)] || [];

      return drafts.length > 0
        ? drafts[drafts.length - 1]
        : null;
    });

  const notes = useSelector(state => state.messages.byId[getNotesChannelNameById(conversationId)] || []);

  const adminTypingSignals = useSelector(state => state.signals.byId[getAdminTypingChannelNameById(conversationId)] || []);
  const adminTypingNoteSignals = useSelector(state => state.signals.byId[getAdminTypingNoteChannelNameById(conversationId)] || []);
  const allAdminTypingSignals = [...adminTypingSignals, ...adminTypingNoteSignals];
  allAdminTypingSignals.sort((a, b) => (a.timetoken > b.timetoken) ? 1 : -1);

  const allUsers = useSelector(state => state.users.byId);
  const loggedInUserId = useSelector(state => state.init.loggedInUser.id);

  const allMessagesAndNotes = [...messages, ...notes];
  allMessagesAndNotes.sort((a, b) => (a.timetoken > b.timetoken) ? 1 : -1);

  const parts = transformToConversationParts(allMessagesAndNotes);

  const partEls = parts.map((p, idx) => {
    switch(p.type) {
      case "message_or_note": return <ConversationPartMessageOrNote key={ idx } part={ p } />;
    }
  });

  const scrollContainerRef = useRef();
  useEffect(() => {
    scrollToBottom(scrollContainerRef.current)
  }, [allMessagesAndNotes.length, lastDraft, conversationId, allAdminTypingSignals.length]);

  useEffect(() => {
    const resizeListener = () => {
      scrollToBottom(scrollContainerRef.current);
    }
    window.visualViewport.addEventListener('resize', resizeListener);
    return () => window.visualViewport.removeEventListener(resizeListener);
  }, []);

  /*
  *  Typing indicators + Live Preview for visitor messages
  *  */
  if (lastDraft) {
    const visitorMessages = messages.filter(m => m.userMetadata.authorType === "visitor");

    const lastVisitorMessageAuthorTimetoken = visitorMessages.length > 0
      ? visitorMessages[visitorMessages.length - 1].message.authorTimetoken
      : 0;

    if (lastDraft.message.authorTimetoken > lastVisitorMessageAuthorTimetoken
        /* only show the draft if it is more recent than the last message */

        && lastDraft.message.text.trim().length > 0
        /* ignore empty messages also as that would look confusing */
    ) {
      partEls.push(<ConversationPartVisitorDraft key="visitor-draft" draft={ lastDraft } />);
    }
  }

  /*
  * For each admin typing signal we have, determine if we need to add typing indicators
  *  */
  const groupedAdminTypingSignals = groupSignalsByPublisherId(allAdminTypingSignals);

  for (const [adminUserId, signals] of Object.entries(groupedAdminTypingSignals)) {
    const lastSignal = signals[signals.length - 1];

    // check if signal is within last N seconds
    const TYPING_INDICATOR_DURATION_SECONDS = 5;
    const lastSignalTimestamp = parseInt(lastSignal.timetoken) / 10000;
    if ((Date.now() - lastSignalTimestamp) > (TYPING_INDICATOR_DURATION_SECONDS * 1000)) {
      continue;
    }

    // find all the messages for THIS ADMIN ONLY
    const adminMessagesOrNotes = allMessagesAndNotes.filter(m =>
      m.userMetadata.authorType === 'admin'
      && m.userMetadata.authorId === adminUserId);

    const lastMessageOrNoteAuthorTimetoken = adminMessagesOrNotes.length > 0
        ? adminMessagesOrNotes[adminMessagesOrNotes.length - 1].message.authorTimetoken
        : 0;

    if (lastSignal.message.authorTimetoken > lastMessageOrNoteAuthorTimetoken
        /* only show the typing indicators if it is more recent than the last message */

        && lastSignal.message.type !== "stop_typing"
        /* don't show if the last signal was that typing stopped */
    ) {
      const adminUser = allUsers[adminUserId];

      if (adminUser && loggedInUserId !== adminUserId) {
        partEls.push(
          <ConversationPartAdminTypingIndicators
            key={ `admin-typing-${ adminUserId }` }
            profileData={ adminUser }
            isNote={ lastSignal.channel.includes(adminTypingNoteChannelPrefix) } />
        );
      }
    }
  }

  return (
    <div ref={ scrollContainerRef } className={ classNames(c.container, className) }>
      <div className={ c.inner }>
        { partEls }
      </div>
    </div>
  );
}
