import React from "react"
import c from "./conversationPartAdminTypingIndicators.module.scss"
import {PulseLoader} from "react-spinners";
import Avatar from "../../../components/shared/avatar";
import classNames from "classnames";

export default function ConversationPartAdminTypingIndicators({ profileData, isNote }) {
  const cx = classNames(c.bubble, {
    [c.isNote]: isNote,
  })

  return (
    <div className={ c.container }>
      <Avatar className={ c.avatar } profileUrl={ profileData.profileUrl } isForAdmin={ true } />

      <div className={ c.content }>
        <div className={ c.bubbleCtn }>
          <div className={ cx }>
            <PulseLoader color="#808080" size="8" />
          </div>
        </div>
      </div>
    </div>
  );
}
