import React from "react"
import c from "./conversationMain.module.scss"
import ConversationHeader from "./conversationHeader";
import ConversationStream from "./conversationStream";
import MessageCompose from "./messageCompose";

export default function ConversationMain({ onOpenMobileMenu }) {
  return (
    <div className={ c.container }>
      <ConversationHeader onOpenMobileMenu={ onOpenMobileMenu } />

      <ConversationStream className={ c.conversationStream }/>

      <MessageCompose />
    </div>
  );
}
