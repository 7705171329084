import { createSlice } from '@reduxjs/toolkit';

export const initSlice = createSlice({
  name: "init",
  initialState: {
    status: "idle",
    loggedInUser: { }
  },
  reducers: {
    initCompleted: (state, action) => {
      state.status = "completed";
      state.loggedInUser = action.payload;
    }
  }
});

export const { initCompleted } = initSlice.actions;

export default initSlice.reducer;
