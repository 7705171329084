import React, {useRef, useState, useEffect} from "react"
import c from "./messageCompose.module.scss"
import classNames from "classnames"
import {usePubNub} from "pubnub-react";
import {useMediaQuery} from "../../utils/useMediaQuery";
import {
  getAdminTypingChannelNameById,
  getAdminTypingNoteChannelNameById,
  getChannelNameById,
  getNotesChannelNameById
} from "../../utils/channelNames";
import {useParams} from "@reach/router";
import {useSelector} from "react-redux";
import {useDebounce} from "use-debounce";

export default function MessageCompose() {
  const { conversationId } = useParams()
  const channelName = getChannelNameById(conversationId);
  const adminTypingChannelName = getAdminTypingChannelNameById(conversationId);
  const adminTypingNoteChannelName = getAdminTypingNoteChannelNameById(conversationId);
  const notesChannelName = getNotesChannelNameById(conversationId);
  const pubnub = usePubNub();
  const [isNote, setIsNote] = useState(false);
  const loggedInUser = useSelector(state => state.init.loggedInUser);

  const [draft, setDraft] = useState('');
  const [debouncedDraft, cancelDebounceDraft] = useDebounce(draft, 500, { maxWait: 500, leading: true });

  const inputRef = useRef();
  const [hasFocus, setFocus] = useState(false);

  const isTouchDevice = useMediaQuery("@media (hover: none)");

  const sendMessage = () => {
    if (draft && draft.trim().length > 0) {
      /* It is IMPORTANT That we do this synchronously because otherwise
       iOS Safari won't keep the keyboard open. For example, if we did
       this _after_ the pubnub sending succeeded, the keyboard would go
       away and the user would have to tap it again to bring it back up.
      */
      setDraft('');
      inputRef.current.focus();

      // we need to cancel the debounce for the draft update because otherwise
      // it could happen that we send the draft update AFTER we have already sent
      // the actual message
      cancelDebounceDraft();

      // publish message is async
      pubnub.publish({
        channel: isNote ? notesChannelName : channelName,
        message: {
          text: draft,
          authorTimetoken: Date.now()
        },
        meta: {
          type: "text", // only "text" for now
          authorId: loggedInUser.id,
          authorType: "admin", // admin|visitor
        }
      });
    }
  }

  const handleKeyPress = e => {
    if (e.key === "Enter" && !(e.shiftKey || isTouchDevice)) {
      sendMessage();
      e.preventDefault();
    }
  };

  const handleOnChange = (e) => {
    const text = e.target.value;

    setDraft(text);

    // if the draft text was cleared, we want to send the signal immediately;
    // this has the benefit that the typing indicators will stop faster, rather than
    // having to wait for debounce
    if (text.length === 0) {
      sendTypingIndicators("");
    }
  }

  useEffect(() => {
    // only send the signal if length > 0 because we would've already sent sooner
    // (not debounced) if it was empty
    if (debouncedDraft.length > 0

        /* Also check if the CURRENT draft is not empty. This is to prevent a rare
        *  race condition where the message has been sent, the draft is already
        *  set to empty, but the cancel of the debounce isn't fast enough to prevent
        *  the typing indicator from being sent, which would then
        *  happen AFTER the message*/
        && draft.length > 0
    ) {
      sendTypingIndicators(debouncedDraft);
    }
  }, [debouncedDraft]);

  const sendTypingIndicators = (msg) => {
    const type = (msg && msg.trim().length > 0) ? "is_typing" : "stop_typing";

    pubnub.signal({
      channel: isNote ? adminTypingNoteChannelName : adminTypingChannelName,
      message: {
        type: type,
        authorTimetoken: Date.now()
      },
    });
  }

  const onInputFocus = () => {
    // this is needed in order to reliably fix an issue with the scroll position
    // when the virtual keyboard comes up
    window.scrollTo(0, 0);
    setFocus(true)
  };

  const onInputBlur = () => {
    setFocus(false);
  }

  const onTabButtonClicked = (isNote) => {
    setIsNote(isNote);

    // we need to clear the draft so that typing indicators also stop and the
    // stop indicator message gets sent
    setDraft('');

    // needs to be called manually since setting the value using the state
    // won't call the onChange callback
    sendTypingIndicators('');

    // always want to set focus on the input when switching tabs
    inputRef.current.focus();
  }

  const cx = classNames(c.container, {
    [c.isNote]: isNote,
    [c.hasFocus]: hasFocus
  })

  return (
    <div className={ cx }>
        <div className={ c.tabCtn }>

          <a
            className={ classNames({[c.active]: !isNote}) }
            onClick={ e => { e.preventDefault(); onTabButtonClicked(false); } }
          >Reply</a>

          <a
            className={ classNames({[c.active]: isNote}) }
            onClick={ e => { e.preventDefault(); onTabButtonClicked(true); } }
          >Note</a>

        </div>

      <div className={ c.inputCtn}>
        <input
            className={ c.input }
            ref={ inputRef }
            type="text"
            placeholder="Type your message"
            value={ draft }
            onChange={ handleOnChange }
            onKeyPress={ handleKeyPress }
            onFocus={ onInputFocus }
            onBlur={ onInputBlur }
        />
        <button className={ c.sendBtn } onClick={ e => {
          e.preventDefault();
          sendMessage();
        }}>
          { isNote ? 'Add note' : 'Send' }
        </button>
      </div>
    </div>
  );
}
