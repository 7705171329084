import React from "react"
import c from "./conversationHeader.module.scss"
import {useSelector} from "react-redux";
import {selectConversationById} from "../conversationList/conversationsSlice";
import {useParams} from "@reach/router";

export default function ConversationHeader({ onOpenMobileMenu }) {
  const { conversationId } = useParams()
  const conversation = useSelector(state => selectConversationById(state, conversationId));

  return (
    <div className={ c.container }>
      <div className={ c.mobileMenuToggle } onClick={ onOpenMobileMenu }>[-]</div>
      <div className={ c.name }>{ conversation.name }</div>
    </div>
  );
}
