import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

const CustomAuth0Provider = ({ children }) => {
  // const history = useHistory();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      useRefreshTokens={true} // without that it DOES NOT work on Safari, at all
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default CustomAuth0Provider;
