import { createSlice } from '@reduxjs/toolkit';
import {
  channelPrefix,
  getChannelNameById,
  getDraftsChannelNameById,
  getNotesChannelNameById
} from "../../utils/channelNames";
import {fetchAllChannelData, fetchMessageHistory} from "@daniellangnet/pubnub-redux";

/*
    Let's just keep this commented out code around to see what a custom reducer
    would look like if we built it ourselves using the ReduxJS Toolkit instead
    of relaying on the Pubnub-Redux framework. You never know if we need it.
*/
// export const conversationsSlice = createSlice({
//   name: "conversations",
//   initialState: [],
//   reducers: {
//     conversationsLoaded: (state, action) => {
//       return action.payload;
//     }
//   }
// });
//
// export const { conversationsLoaded } = conversationsSlice.actions;
//
// export default conversationsSlice.reducer;

export const loadConversations = () => async (dispatch, getState, context) => {
  const pubnub = context.pubnub.api;

  try {
    await dispatch(fetchAllChannelData({
      include: {
        customFields: true,
        limit: 30
      }
    }));

    // BATCH IMPLEMENTATION
    // Note: this is how it would work if we wanted to batch load
    // using a single API request. The problem is that right now
    // there is not out-of-the-box redux action available in Pubnub-Redux
    // and we'd have to build this on our own. Let's see how bad the
    // "naive" implementation of looping through is before we invest
    // that time.
    // ###################################################
    // const channelIds = result.data.map(c => c.id);
    // const histResult = await pubnub.fetchMessages({
    //   channels: channelIds,
    //   count: 25
    // });

    const allConversations = Object.values(getState().conversations.byId);

    const fetchHistoryPromises = allConversations.map(async c => {
      const fetchChannelHistory = dispatch(fetchMessageHistory({
        channel: c.id,
        includeMeta: true,
        count: 100
      }));

      const conversationId = c.id.split(channelPrefix)[1];

      const fetchNotesHistory = dispatch(fetchMessageHistory({
        channel: getNotesChannelNameById(conversationId),
        includeMeta: true,
        count: 100
      }))

      const fetchDraftHistory = dispatch(fetchMessageHistory({
        channel: getDraftsChannelNameById(conversationId),
        includeMeta: true,
        count: 1 // just the last one
      }));

      await Promise.all([fetchChannelHistory, fetchNotesHistory, fetchDraftHistory]);
    });

    await Promise.all(fetchHistoryPromises);
  } catch(e) {
    console.error(e);
  }
}

export const selectConversationById = (state, conversationId) => {
  return state.conversations.byId[getChannelNameById(conversationId)];
}
