import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import initReducer from "./features/init/initSlice";
import {
  createChannelDataReducer,
  createMessageReducer,
  createSignalReducer,
  createUserDataReducer,
} from "@daniellangnet/pubnub-redux";

export const createCustomStore = (pubnub) => {
  return configureStore({

    reducer: {
      init: initReducer,

      conversations: createChannelDataReducer(),
      users: createUserDataReducer(),
      messages: createMessageReducer(),
      signals: createSignalReducer(),

    },

    // we need to pass in the pubnub api so that the thunks provided
    // by the pubnub redux framework can do their thing
    middleware: getDefaultMiddleware({
      thunk: {
        extraArgument: {
          pubnub: {
            api: pubnub
          }
        }
      }
    })
  });
}
