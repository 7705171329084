/*

  This is a pretty clever way of fixing the issue that on mobile browsers
  (like iOS Safari) the CSS unit `vh` is not really the viewport height, i.e.
  the visible part of the browser. This is because of the address and toolbar
  scrolling away sometimes.

  This "solution" only works with more recent browsers as it depends on
  CSS variables to be available, but totally fine for us.

  It's based on this: https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae

  But this solution uses the `window.visualViewport` which is only supported
  in Safari 13 and up.

 */

const setupViewHeightFix = () => {
  let keyboardClosedHeight;

  const resizeListener = () => {
    if (!keyboardClosedHeight) {
      keyboardClosedHeight = window.visualViewport.height;
    }

    document.querySelector(':root').style
      .setProperty('--vh', window.visualViewport.height/100 + 'px');

    if (window.visualViewport.height < keyboardClosedHeight) {
      // keyboard has just become visible, so we need to scroll to the top
      // in order to make it look correct
      window.scrollTo(0, 0);

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
    }
  };

  window.visualViewport.addEventListener('resize', resizeListener);
  resizeListener();
};

export { setupViewHeightFix };
