import React from "react"
import c from "./loadingComponent.module.scss"
import {BeatLoader} from "react-spinners";
import classNames from "classnames";

export default function LoadingComponent({ className }) {
  return (
    <div className={ classNames(c.container, className) }>
      <BeatLoader />
    </div>
  )
}
