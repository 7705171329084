import {fetchAllUserData, setUserData} from "@daniellangnet/pubnub-redux";

export const loadAndInitUsers = (authUser) => async (dispatch, getState, context) => {
  const uuid = authUser.id;

  // Fetch all the users that already exist
  await dispatch(fetchAllUserData({
    limit: 100,
    include: {
      customFields: true
    }
  }))

  // check if the current user is already in pubnub; if not, create it
  const user = getState().users.byId[uuid];
  if (user) {
    if (user.name !== authUser.name ||
      user.email !== authUser.email ||
      user.profileUrl !== authUser.profileUrl) {
      console.log(`Found user (${ uuid }) but details were different. Updating now.`);

      await dispatch(setUserData({
        id: uuid,
        data: {
          name: authUser.name,
          email: authUser.email,
          profileUrl: authUser.profileUrl
        }
      }));
    } else {
      console.log(`Found user (${ uuid }) and details matched. No action.`);
    }

  } else {
    console.log(`User (${ uuid }) doesn't exist yet. Creating now.`);
    await dispatch(setUserData({
      id: uuid,
      data: {
        name: authUser.name,
        email: authUser.email,
        profileUrl: authUser.profileUrl
      }
    }));
  }
}
