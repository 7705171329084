import React, {useEffect, useState} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Pubnub from "pubnub";
import {PubNubProvider} from "pubnub-react";
import {createCustomStore} from "./store";
import {Provider} from "react-redux";
import AppRouter from "./AppRouter";
import {
  combineListeners,
  createChannelDataListener,
  createMessageListener, createSignalListener,
  createUserDataListener,
  fetchUserData
} from "@daniellangnet/pubnub-redux";
import {doInit} from "./features/init/initAction";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {setupViewHeightFix} from "./utils/viewHeightFix";

const pubnub = new Pubnub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,

  // Ensure that subscriptions will be retained if the network connection is lost
  restore: true,
  heartbeatInterval: 0,
});

const store = createCustomStore(pubnub);

setupViewHeightFix();

function App() {
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();

  const onLoginSuccess = () => {
    // it's important that we set this _before_ we make any pubnub
    // api calls, otherwise the user would be counted as an MAU with a random uuid
    pubnub.setUUID(user.sub);

    // register all the listeners we want
    pubnub.addListener(
      combineListeners(
        createUserDataListener(store.dispatch),
        createMessageListener(store.dispatch),
        createChannelDataListener(store.dispatch),
        createSignalListener(store.dispatch),
      )
    );

    // load all the initial data and do other init tasks with pubnub
    store.dispatch(doInit({
      id: user.sub,
      email: user.email,
      name: user.name,
      profileUrl: user.picture
    }));
  }

  const onLogout = () => {
    // needed to show the user leave immediately rather than waiting for timeout
    pubnub.unsubscribeAll();
  };

  useEffect(() => {
    return onLogout;
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", onLogout);
  }, []);

  // respond to changes in authentication state (login/logout)
  useEffect(() => {
    if (isAuthenticated) {
      onLoginSuccess();
    } else {
      onLogout();
    }
  }, [isAuthenticated]);

  return (
    <Provider store={ store }>
      <PubNubProvider client={ pubnub }>
        <AppRouter />
      </PubNubProvider>
    </Provider>
  );
}

export default withAuthenticationRequired(App);
