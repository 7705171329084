import React from "react";
import c from "./loadingFullpage.module.scss"
import {BeatLoader} from "react-spinners";

export default function LoadingFullpage() {
  return (
    <div className={ c.container }>
      <BeatLoader />
    </div>
  );
}
