import React from "react";
import c from "./conversationDetails.module.scss"

export default function ConversationDetails() {
  return (
    <div className={ c.container }>
      <div className={ c.title }>Details</div>
      <div className={ c.content }>
        Nothing here yet... ¯\_(ツ)_/¯
      </div>
    </div>
  );
}
