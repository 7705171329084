import { differenceInSeconds } from 'date-fns'
import {convertTimetokenToDate} from "../../utils/timetoken";
import {notesChannelPrefix} from "../../utils/channelNames";

export const transformToConversationParts = (messages) => {
  const parts = [];

  messages.forEach((m, idx) => {
    parts.push({
      type: "message_or_note",
      raw: m,
      isFirstInGroup: !areMessagesInSameGroup(m, messages[idx - 1]),
      isLastInGroup: !areMessagesInSameGroup(messages[idx + 1], m)
    });
  });

  return parts;
}

const areMessagesInSameGroup = (a, b) => {
  if (!a || !b) return false;

  const aAuthorId = a.userMetadata.authorId;
  const bAuthorId = b.userMetadata.authorId;

  const aIsNote = a.channel.includes(notesChannelPrefix);
  const bIsNote = b.channel.includes(notesChannelPrefix);

  if (aIsNote !== bIsNote) return false;

  if (aAuthorId === bAuthorId) {
    const aDate = convertTimetokenToDate(a.timetoken);
    const bDate = convertTimetokenToDate(b.timetoken);

    if (differenceInSeconds(aDate, bDate) < 60) {
      return true;
    }
  }

  return false;
}
