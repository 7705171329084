import React from "react"
import {Router, useParams} from "@reach/router"
import SeedDataView from "./views/seedDataView";
import MainView from "./views/mainView";
import {useAuth0} from "@auth0/auth0-react";
import {useSelector} from "react-redux";
import LoadingFullpage from "./components/shared/loadingFullpage";

export default function AppRouter() {
  const { isLoading } = useAuth0();
  const initStatus = useSelector(state => state.init.status);

  if (isLoading || initStatus !== "completed") {
    return <LoadingFullpage />;
  }

  return (
    <Router>
      <SeedDataView path="seed" />
      <MainView path="/:conversationId" />
      <MainView path="/" />
    </Router>
  );
}
