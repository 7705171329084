import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import CustomAuth0Provider from "./utils/customAuth0Provider";

ReactDOM.render(
  <CustomAuth0Provider>
    <App />
  </CustomAuth0Provider>,
  document.getElementById('root')
);
