import React, {useEffect, useMemo, useState} from "react"
import c from "./dateDisplay.module.scss"
import {convertTimetokenToDate} from "../../utils/timetoken";
import { differenceInSeconds, formatDistanceToNow, subDays } from 'date-fns'

const formatClean = (str) => {
  return str
    .replace("about", "")
    .trim();
}

const formatShort = (str) => {
  if (str === "less than a minute") return "now";

  const parts = str.split(" ");

  // not sure what to do... this should never happen
  if (parts.length < 2) return "?";

  const numberPart = parts[parts.length - 2];
  const intervalPart = parts[parts.length - 1]
    .replace("minutes", "m")
    .replace("minute", "m")
    .replace("hours", "h")
    .replace("hour", "h")
    .replace("days", "d")
    .replace("day", "d")
    .replace("months", "mth")
    .replace("month", "mth")
    .replace("years", "y")
    .replace("year", "y");

  return `${ numberPart }${ intervalPart }`;
}

export default function DateDisplay({ timetoken, shortFormat }) {
  // We just need some state that we can change during the interval so that the
  // component rerenders. We don't actually use the counter for anything.
  const [unusedCounter, setCounter] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(c => c + 1);
    }, 10000); // 10 seconds

    return () => clearInterval(interval);
  }, []);

  const date = useMemo(() => convertTimetokenToDate(timetoken), [timetoken]);
  const formatted = formatDistanceToNow(date);
  const clean = useMemo(() => formatClean(formatted), [formatted]);
  const final = shortFormat ? formatShort(clean) : clean;

  return (
    <div className={ c.container }>
      { final }
    </div>
  );
}
